@import url('https://fonts.googleapis.com/css?family=Quando|Vidaloka');

.header {

	h1 {
		font-family: 'Quando', serif;
		padding: 15px 0;
		text-align: center;
		font-size: 35px;
		&:hover {
			text-decoration: none;
			text-underline: none;
		}
	}

	.copyright {
		color: #a2a2a2;
		font-size: 12px;
		text-align: center;
	}


		ul {
			padding-left: 0;
			list-style-type: none;

			li {
				text-align: center;
				font-size: 15px;
				text-transform: uppercase;
				padding: 12px 0;

				a:hover {
					text-decoration: none;
				}

			}

			.current_page_item a {
				color: #43A047;
			}

		}


	.logo {

		a:hover {
			text-decoration: none;
		}

		h1 {
			margin: 0;
			padding: 40px 0 20px 0;
		}

	}

}

.mobile-nav-icon {
	display: none;
}

.nav .copyright {
	display: none;
}

@media screen and (max-width: 970px) {

	.header {

		hr, .copyright {
			display: none;
		}

		.logo h1 {
			text-align: left;
			padding: 15px;
		}

	}

	.mobile-nav-icon {
		display: block;
		position: absolute;
		right: 15px;
		top: 22px;
		font-size: 14px;
		cursor: pointer;
	}

	.nav {
		position: absolute;
		display: none;
		overflow: hidden;
		background: #fff;
		right: 0;
		left: 0;
		top: 70px;
		z-index: 101;
		padding: 50px;
		padding-right: 20px;
		box-shadow: 0px 2px 16px -11px rgba(0, 0, 0, 0.75);

		.copyright {
			display: block;
		}

	}

	.header ul li {
		padding: 15px 0;
	}

}

@media screen and (max-width: 680px) and (min-width: 500px) {

	.about-me-image {
		display: none;
	}

	.about-me-content {
		width: 100%;
	}

}

@media screen and (max-width: 500px) {

	.about-me-image {
		display: block;
		width: 100%;
		margin-bottom: 15px;
	}

	.about-me-content {
		width: 100%;
	}

}
