@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans');
body {
	font-family: 'Noto Sans', sans-serif !important;
	background-color: #f1f1f1;
	color: #212121;
}

.header {
	float: left;
	width: 250px;
	height: 100%;
	position: fixed;
	top: 0;
	background-color: #fff;
	border-right: 1px solid rgba(0,0,0,.14);
	box-shadow: 0 1px 2px rgba(0,0,0,.1);
}

.header-container {
	margin-top: -100px;
	padding: 30px;
	margin-bottom: 50px;
}

.wrapper {
	margin-left: 250px;
}

.container {
	border: 1px solid rgba(0,0,0,.14);
	box-shadow: 0 1px 2px rgba(0,0,0,.1);
	background-color: #fff;
	max-width: 1400px;
	width: 100%;
}

p {
	font-size: 15px;
}

.header-image {
	height: 400px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.btn-primary {
	background-color: #43A047;
	color: #fff;
	padding: 11px 18px;
	border-radius: 0px;
	background-image: none;
	border: 0;

	&:hover {
		background-color: #66BB6A;
	}

}

h1, h2, h3, h4 {
	font-family: 'Quando', serif;
}

.contact {

	h1 {
		text-align: center;
		margin-bottom: 30px;
		font-size: 30px;
	}

	form {
		max-width: 360px;
		width: 100%;
		margin: 0 auto;
	}

	input[type="text"], input[type="email"], textarea {
		padding: 10px 15px;
	}
	input[type="submit"] {
		background-color: #43A047;
		color: #fff;
		padding: 11px 18px;;
		background-image: none;
		border: 0;
		display: inline-block;
		margin-bottom: 0;
		font-weight: normal;
		text-align: center;
		vertical-align: middle;
		-ms-touch-action: manipulation;
		touch-action: manipulation;
		cursor: pointer;
		white-space: nowrap;
		font-size: 14px;
		line-height: 1.42857143;
		user-select: none;
	}
}


@media screen and (max-width: 1500px) {


	.header {
		width: 200px;
	}

	.wrapper {
		margin-left: 200px;
	}


	.header-container {
		margin-top: -150px;
	}

}

@media screen and (max-width: 1600px){

	.container {
		max-width: 92%;
	}

	p {
		font-size: 14px;
	}

}

@media screen and (min-width: 1930px){

	.header {
		width: 280px;
	}

	.wrapper {
		margin-left: 280px;
	}

	.container {
		max-width: 1500px;
	}

	p {
		font-size: 16px;
	}

}

@media screen and (min-width: 2200px) {

	.header {
		width: 300px;
	}

	.wrapper {
		margin-left: 300px;
	}

	.container {
		max-width: 1550px;
	}

	p {
		font-size: 17px;
	}

}

@media screen and (max-width: 970px) {

	.header-image {
		height: 300px;
	}

	.header {
		height: 70px;
		width: 100%;
		position: relative;
		background-color: #fff;
		border-right: 0px;
		box-shadow: 0 1px 2px rgba(0,0,0,.1);
	}

	.wrapper {
		margin-left: 0px;
	}

	.contact {
		text-align: center;

		h1 {
			margin-bottom: 30px;
			font-size: 30px;
		}

	}


}