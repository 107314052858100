.photo-static-block {
	padding: 0 5px;
	margin-top: 10px;
}

.about-me {
	color: #010101;
	padding-bottom: 30px;
	margin-top: -30px;
	margin-right: -30px;
	margin-left: -30px;
	padding: 30px;

	h2 {
		margin-top: 0px;
	}

}

.home-recent-photos {
	margin-top: 30px;
	text-align: center;

	h2 {
		margin-bottom: 30px;
	}

}

@media screen and (min-width: 1200px) and (max-width: 1350px) {

	.about-me-content {
		width: 58.33333333%;
	}

	.about-me-image {
		width: 41.66666667%;
	}

}
