.gallery-container {

	h2 {
		text-align: center;
		margin-bottom: 30px;
	}

	.col-md-3 {
		padding: 0 5px;

		h3 {
			color: #fff;
			text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
			padding: 0px 5px;
			text-align: center;
			margin: 0;
			line-height: auto;
			position: absolute;
			top:50%;
			transform: translateY(-50%);
			width: 100%;
			left: 0;
			z-index: 101;
		}

	}

	.photo-static-block {
		transition: 1s ease;
		margin-top: 10px;
		text-align: center;
		opacity:1;
		background-size: cover;
		position: relative;
		background-repeat: no-repeat;
		background-position: center center;

		.picture-overlay {
			transition: .5s ease;
			opacity: 0;
			position: absolute;
			top:0;  bottom: 0;  left: 0;  right: 0;
			background-color: #43A047;
			z-index: 100;
		}

		&:hover {

			.picture-overlay {
				opacity:0.7;
			}

		}

	}

}

.template-gallery {

	.photo-static-block {
		height: 300px;
	}

}




/* ----------------------- Detail ---------------------- */
.picture-category-details {

}

.category-description, .recent-description {
	color: #757575;
	padding: 10px 0;
}

.masonry-container {
	opacity: 0;
	transition: all 1s ease;

	.photo-static-block {
		height: 300px;
		padding: 0;
	}

	.col-md-3 {
		padding: 0;
		width: calc(25% - 8px);
	}

}

.spinner {
	margin: 100px auto 0;
	width: 70px;
	text-align: center;
}

.spinner > div {
	width: 18px;
	height: 18px;
	background-color: #43A047;

	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
	0%, 80%, 100% { -webkit-transform: scale(0) }
	40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
	0%, 80%, 100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	} 40% {
		  -webkit-transform: scale(1.0);
		  transform: scale(1.0);
	  }
}

@media screen and (max-width: 1400px) {
	.masonry-container {

		.photo-static-block {
			height: 240px;
			padding: 0;
		}

	}

	.template-gallery {

		.photo-static-block {
			height: 240px;
		}

	}


}

@media screen and (max-width: 1200px) {
	.masonry-container {

		.photo-static-block {
			height: 190px;
			padding: 0;
		}

	}

	.template-gallery {

		.photo-static-block {
			height: 190px;
		}

	}


}

@media screen and (max-width: 1000px) {

	.masonry-container .col-md-3 {
		padding: 0;
		width: calc(33.33% - 8px);
	}

}

@media screen and (max-width: 650px) {

	.masonry-container .col-md-3 {
		padding: 0;
		width: calc(50% - 8px);
	}

}

@media screen and (max-width: 500px) {

	.masonry-container .col-md-3 {
		padding: 0;
		width: 100%;
	}

}