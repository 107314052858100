@charset "UTF-8";

/**
 * UI Kit
 */
@import "ui/bootstrap";
@import "ui/bootstrap-theme";
@import "ui/defaults";
@import "ui/fonts";

@import "ui/header";
@import "ui/footer";

/**
 * Pages
 */
@import "pages/404";
@import "pages/front-page";
@import "pages/single";
@import "pages/gallery";

